import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import React from 'react'
import {
    AttachMoneyRounded,
    BlurCircularOutlined,
    DashboardOutlined,
    DescriptionOutlined,
    DesktopWindows,
    FolderOpenRounded,
    HistoryRounded,
    NaturePeopleOutlined,
    PermIdentity,
    SettingsRounded,
    LockOpen,
    LocalOffer,
    DonutLarge,
    FormatListBulleted,
    FindInPage,
    VerifiedUser,
} from '@material-ui/icons'
import DataUsageIcon from '@material-ui/icons/DataUsage'
import { Icon } from 'views/components/CustomIcon'
import {makeStyles} from '@material-ui/core/styles'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import SupervisorAccountRoundedIcon from '@material-ui/icons/SupervisorAccountRounded'
import {Link, Route} from 'react-router-dom'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleIcon from '@material-ui/icons/People';
import PaymentIcon from '@material-ui/icons/Payment';
import LinkedinBulk from "../../../assets/linkedin_bulk.png"
import dataExtraction from "./assets/Lead extractions.svg"
const useStyles = makeStyles((theme) => ({
    listColor: {
        // backgroundColor: "#7A8BED",
        // backgroundColor: "#00bcd429",
        backgroundColor: 'rgb(66 99 235 / 9%)',
        padding: '.8rem 1rem 0.8rem 22px',
        borderLeft: `5px solid ${theme.palette.primary.main}`
    }, listDefault: {
        padding: '.8rem 1rem 0.8rem 22px', '&:hover': {
            backgroundColor: '#cccccc5e'
        }
    }, listItemActiveText: {
        fontSize: '1rem', // color: "#ffffff"
        color: theme.palette.primary.main, fontWeight: 600
    }, listItemDefaultText: {
        fontSize: '1rem', color: '#707070'
    }, listItemIconActive: {
        // color: "#fff"
        color: theme.palette.primary.main
    }, listItemIconDefault: {
        color: theme.palette.primary.main
    }
}))

const Data = ({home, props}) => {
    const classes = useStyles()

    //default styles
    let listDefault = classes.listDefault
    let listItemIconDefault = classes.listItemIconDefault
    let listItemDefaultText = classes.listItemDefaultText

    //active color
    let listItemStyles = classes.listColor
    let listItemIcon = classes.listItemIconActive
    let listItemText = classes.listItemActiveText

    const NavLink = ({to, children, className, activeClassName, ...rest}) => {
        const path = typeof to === 'object' ? to.pathname : to
        return (<Route
            path={path}
            children={({match}) => {
                const isActive = match
                return (<Link {...rest} to={to}>
                    {typeof children === 'function' ? children(isActive) : children}
                </Link>)
            }}
        />)
    }

    return (<div>
        {home && HomeArray.map((item, k) => {
            return (<NavLink to={`${item.name === 'billing' ? `/${item.name}` : `/${item.name}`}`} key={k}>
                {(isActive) => (<ListItem button className={isActive ? listItemStyles : listDefault}>
                    <ListItemIcon
                        className={isActive ? listItemIcon : listItemIconDefault}>{item.icon}</ListItemIcon>
                    <ListItemText disableTypography={true}
                                  className={isActive ? listItemText : listItemDefaultText}>
                        {item.title}
                    </ListItemText>
                </ListItem>)}
            </NavLink>)
        })}
        {!home && AccountArray.map((item, k) => {
            return (<NavLink to={`/account/${item.name}`} key={k}>
                {(isActive) => (<ListItem button className={isActive ? listItemStyles : listDefault}>
                    <ListItemIcon
                        className={isActive ? listItemIcon : listItemIconDefault}>{item.icon}</ListItemIcon>
                    <ListItemText disableTypography={true}
                                  className={isActive ? listItemText : listItemDefaultText}>
                        {item.title}
                    </ListItemText>
                </ListItem>)}
            </NavLink>)
        })}
    </div>)
}

const HomeArray = [
{name: 'free-credits', title: 'Free Credits', icon: <AttachMoneyIcon/>},

{
    name: 'home',
    title: 'Credits Usage',
    icon: <DataUsageIcon/>
}, 

{name: 'leads', title: 'DIN Leads Extracted', icon: <FormatListBulleted/>}, 

{name: 'gstleads', title: 'GST Leads Extracted', icon:  <Icon name="gst" color="primary" fontSize="medium" />}, 
    
    {
        name: 'linkedin-requests',
        title: 'Linkedin Requests',
        icon: <LinkedInIcon/>
    }, {
        name: 'linkedin-bulk-requests',
        title: 'Linkedin Bulk Requests',
        icon: <img src={LinkedinBulk} width={25} height={25}/>
    },

    {name: 'din', title: 'Search DIN Details', icon: <FindInPage/>},
    {name: 'gst', title: 'Search  GST Details', icon: <FindInPage/>},

    {
        name: 'email-verify', title: 'Verify your Email', icon: <VerifiedUser/>
    },
    {name: 'teams', title: 'Teams', icon: <PeopleIcon/>}, {
        name: 'recharge-history',
        title: 'Recharge History',
        icon: <PaymentIcon/>
    },

]

const AccountArray = [{name: 'profile', title: 'Profile', icon: <PermIdentity/>}, {
    name: 'settings',
    title: 'Settings',
    icon: <SettingsRounded/>
}, {name: 'leads', title: 'Leads Extracted', icon: <DataUsageIcon/>}, {
    name: 'billing',
    title: 'Billing',
    icon: <AttachMoneyRounded/>
}, {name: 'department', title: 'Department', icon: <NaturePeopleOutlined/>}, {
    name: 'activity-logs',
    title: 'Activity Logs',
    icon: <HistoryRounded/>
}]

export default React.memo(Data)
