import axios from 'axios'
import { axiosinstance } from '../utils/axios-instance'

export const razorpayAxiosInstance = axios.create({
  baseURL: 'https://api.razorpay.com',
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json'
  }
})


export function generateServerToken(value) {
  return axiosinstance.post('/api/v1/users/create-server-token', value)
}
export function getListToken() {
  return axiosinstance.get('/api/v1/users/list-tokens')
}
export function revokeToken(value) {
  return axiosinstance.post(`/api/v1/users/revoke-token?api_key=${value}`)
}
export function chargeebeeUrlApi() {
  return axiosinstance.get("/api/v1/users/chargebee-frontend-data");
}
export async function sendOtpCall(data) {
  return axiosinstance.post("/api/v1/users/send-otp", data)
}
export async function submitOtpCall(data) {
  return axiosinstance.post("/api/v1/users/submit-otp", data, { withCredentials: true })
}
export async function refreshTokenCall() {
  return axiosinstance.post("/api/v1/users/refresh-token", null, { withCredentials: true })
}
export function setUserOnboard(data) {
  return axiosinstance.post(`/api/v1/surereach/users/set-user-onboard`, data)
}
export function logoutCall() {
  return axiosinstance.post(`/api/v1/users/logout`, null, { withCredentials: true })
}