
import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import useStyles from './styles';
import { useSelector } from 'react-redux';
import { getUserDetails } from 'getDetails/actions';
import { useDispatch } from 'react-redux';
import CustomSnackbar from 'views/components/custom-snackbar';
import { CircularProgress } from '@material-ui/core';
export default function EmailStep({ onSubmit , setEditEmail,editEmail }) {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
 
  let { loader, reachData, companyDetailData, directorDetailData, reachDirectorData, loading, emailRes, errormsg, userDetails, errormsgemail , addEmailRes, sendEmailLoader  } = useSelector((state) => state.clientData);
  const [errorMesg, setErrorMesg] = useState()
  const dispatch = useDispatch()
  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };
console.log("emailResemailRes", emailRes , !!emailRes?.client_id);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError('Email is required');
    } else if (!validateEmail(email)) {
      setError('Please enter a valid email address');
    } else {
      setError('');
      onSubmit(email);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (error) {
      setError('');
    }
  };

  const handleResendClick =()=>{
    setEditEmail(true)
  }


  const handleClose = () => {
    setErrorMesg(null)
    dispatch({
      type: "SEND_EMAIL_FAILURE", payload: null
       })

  dispatch({
    type:"ADD_EMAIL_FAILURE", payload: null
   })
  }


  useEffect(() => {
     if(userDetails?.email){
      setEmail(userDetails?.email)
     }

  }, [userDetails])

  useEffect(()=>{
    if(errormsgemail?.message){
      setErrorMesg(errormsgemail?.message)
    }
    if(errormsg?. data?.message){
      setErrorMesg(errormsg?. data?.message)
    }
  },[errormsgemail,  errormsg])

  useEffect(()=>{
    dispatch( getUserDetails())
  },[addEmailRes])


  return (
    <div className={classes.stepContainer}>
      <div className={classes.logo}>
        <MailOutlineIcon />
      </div>
      <Typography variant="h4" className={classes.title}>
       {userDetails?.email?"Verify Your Email":"Add Your Email"}
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
    
      {userDetails?.email?"Please provide the email address associated with your account. We'll send a verification code to this address.":"Please provide your email address to create your account."} 
      <Button onClick={handleResendClick} className={classes.resendButton}>
          Edit Email
        </Button> 
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField
          type="email"
          value={   email||userDetails?.email}
          disabled={userDetails?.email}
          onChange={handleEmailChange}
          placeholder="johndoe@example.com"
          required
          fullWidth
          variant="outlined"
          error={!!error}
          helperText={error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutlineIcon />
              </InputAdornment>
            ),
          }}
          className={classes.emailInput}
        />
        <Button
          type="submit"
          variant="contained"
          className={classes.submitButton}
          disabled={sendEmailLoader}
        >

        {sendEmailLoader?
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <CircularProgress style={{'color': 'white'}} size={30}/> 
        <div style={{marginLeft: "20px"}}> Loading...</div>
        </div>
        :userDetails?.email?"Send Verification Code":"Register Email"}
        </Button>
      </form>

      <CustomSnackbar
        display={!!errorMesg} // Pass a boolean value here
        message={errorMesg}
        dismissNotification={handleClose} // Ensure this updates the state properly
        severity={"error"}
      />


       


    </div>
  );
}
