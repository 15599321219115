import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { MailOutline } from "@material-ui/icons";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import { authActions } from "auth";

import Logo from "assets/logo.png";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Link, matchPath, withRouter } from "react-router-dom";
import Header from "../header";
import Body from "./Body";
import Data from "./data";

import { clientActions } from "getDetails";
import ReactGA from "react-ga";

const drawerWidth = 260;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    position: "relative",
    display: "flex",
  },
  sideBarLogo: {
    height: "6rem",
  },
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: "#ffff", // overflowY: "scroll",
    outline: "none",
    height: "97%",

    boxShadow: theme.shadows[5], // "& .focus-visible": {
    //   outline: "none",
    // },

    // padding: "1px 19px 1px",
  },
  appBar: {
    backgroundColor: "#fff",
    boxShadow: "none",
    borderBottom: "1px solid #C2C2C2",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    position: "relative", // position: 'fixed'
    zIndex: theme.zIndex.drawer - 10,
    whiteSpace: "nowrap",
    width: drawerWidth,
    maxHeight: "100vh",
    overflow: "hidden",
    backgroundColor: "#fff",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  scroll: {
    color: "#fff",
    overflowY: "auto",
    overflowX: "hidden",
    "scrollbar-width": "5px",
    "&::-webkit-scrollbar": {
      // display: "none"
      width: 5,
    },
  },
  cowinBold: {
    color: "#0084ec",
  },
  fab: {
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
  },
});

const GenerateButton = withStyles((theme) => ({
  root: {
    // height: "2.5rem",
    textTransform: "capitalize",
    fontSize: "16px",
    letterSpacing: "1px",
    margin: "25px 10%",
    padding: "12px 32px",
    fontFamily: "AvertaRegular",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
    fontWeight: 800,
    color: "#fff",
    "&:hover": {
      background: "#fff",
      color: theme.palette.primary.main,
    },
    "& .MuiTouchRipple-root": {
      opacity: 0.2,
      zIndex: -1,
    },
  },
}))(Button);

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true, // minimize sidebar
      modalOpen: false,
      authenticated: false,
      home: true, // Different options/routes for home and account settings
      show: true, // To hide sidebar completely eg. if not authenticated
      // openModal: false, //To open modal
      modalStyle: this.getModalStyle(),
      currentPlan: {},
      userDetails: null,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    var newState = prevState;
    if (nextProps.outerToggleDrawer !== newState.open) {
      newState.open = nextProps.outerToggleDrawer;
      return newState;
    }

    if (nextProps.userDetails !== newState.userDetails) {
      if (nextProps?.userDetails?.team_id && !nextProps.userTeamDetails) {
        nextProps.getUserTeamDetails({
          team_id: nextProps?.userDetails?.team_id,
        });
      }
      newState.userDetails = nextProps.userDetails;
    }

    if (nextProps.authenticated !== prevState.authenticated) {
      newState.authenticated = nextProps.authenticated;
      if (!nextProps.userDetails && nextProps.authenticated) {
        nextProps.getUserDetails();
      }
    }
    return newState;
  }

  componentDidMount() {
    const { location } = this.props;
    // console.log("Location", location);
    const match = matchPath(location.pathname, {
      path: "/account",
      exact: false,
      strict: true,
    });

    this.setState({ home: !match });
    // console.log("Props", this.props);
    // console.log("Current Plan", this.props.currentPlan);
    //
    // this.props.currentPlanDetail();
    // console.log("Props", this.props.currentPlan);
    // if (this.props.currentPlan?.data?.plan_id === null) {
    //   this.setState({ modalOpen: true });
    // }

    // if (this.state.openModal) {
    //   console.log("OpenModal", this.state.openModal);
    //   window.addEventListener("keypress", (e) => {
    //     if (e.key === "Escape" || e.key === "esc") {
    //       this.closeModal();
    //     }
    //   });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log(window.location.pathname + window.location.search)
    const match = matchPath(location.pathname, {
      path: "/account",
      exact: false,
      strict: true,
    });

    const newDoc = matchPath(location.pathname, {
      path: "/document",
      exact: false,
      strict: true,
    });

    if (prevState.home !== !match) {
      this.setState({ home: !match });
    }
    if (prevState.show !== !newDoc) {
      this.setState({ show: !newDoc });
      this.setState({ open: !newDoc });
    }
  }

  handleDrawer = () => {
    // this.setState({ open: !this.state.open });
    this.props.setOuterToggleDrawer(!this.state.open);
  };

  handleLogOut = () => {
    this.props.signOut();
    // console.log("SIGNOUT");
  };
  handleModal = () => {
    this.props.changeModal(true);
    this.setState({ modalOpen: false });
  };

  getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  handleClose = (event, reason) => {
    // console.log("Event", event, "reason", reason);
    // eslint-disable-next-line no-constant-condition
    if (reason === "escapeKeyDown" || "backdropClick") {
      this.props.changeModal(false);
    }
    this.setState({
      modalOpen: false,
    });
  };

  closeModal = () => {
    this.props.changeModal(false);
  };

  render() {
    const { classes, authenticated, openModal, pop_up } = this.props;
    // console.log("Open", openModal);
    const { open, home, show } = this.state;
    // console.log(this.props);

    const showNav = authenticated && show;

    return (
      <div className={classes.root}>
        {/* {pop_up && (<PopupComponent />)} */}
        {authenticated && (
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose,
                open && classes.drawerPaperOpen,
              ),
            }}
            open={open}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.sideBarLogo}
            >
              {open && (
                <Grid
                  container
                  item
                  xs={10}
                  justifyContent="center"
                  style={{ paddingTop: 0 }}
                >
                  <Link to="/">
                    <img src={Logo} style={{ height: "35px" }} />
                    {/*<Typography*/}
                    {/*  variant="h4"*/}
                    {/*  style={{*/}
                    {/*    marginBottom: 20,*/}
                    {/*    display: "flex",*/}
                    {/*    alignItems: "center",*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <b style={{ marginLeft: 10 }}>No</b>*/}
                    {/*  <CodeIcon style={{ fontSize: "60" }} color={"primary"} />*/}
                    {/*  <b>Pdf</b>*/}
                    {/*</Typography>*/}
                  </Link>
                </Grid>
              )}
              <Grid
                container
                item
                xs={open ? 2 : 12}
                justifyContent="center"
                alignItems="center"
                className={classes.sideBarLogo}
                style={{ paddingTop: 0 }}
              >
                <Box mr={open ? 2 : 0}>
                  <IconButton onClick={this.handleDrawer}>
                    <ChevronRightIcon
                      style={{
                        transform: `${
                          !open ? "rotateZ(0deg)" : "rotateZ(180deg)"
                        }`,
                        transition: "all 250ms ease-in",
                      }}
                    />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Divider style={{ margin: "0 10%" }} />
            {/*
            {open ? (
              <GenerateButton
                // component={Link}
                // to="/document/create"
                startIcon={<AddIcon />}
                onClick={this.handleModal}
              >
                Generate API Key
              </GenerateButton>
            ) : (
              <Fab
                onClick={this.handleModal}

                size="medium"
                color="primary"
                className={classes.fab}
                style={{ margin: "19px auto", minHeight: "3rem" }}
              >
                <AddIcon />
              </Fab>
            )} */}

            <Modal
              open={openModal}
              onClose={(event, reason) => this.handleClose(event, reason)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"

              // style={{ margin: "10px" }}
            >
              <div>
                <Body openModal={openModal} closeModal={this.closeModal} />
              </div>
            </Modal>
            {/* {this.state.modalOpen && (
              <Modal
                open={this.state.modalOpen}
                onClose={this.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                // style={{ margin: "10px" }}
              >
                <div style={this.state.modalStyle} className={classes.paper}>
                  <Pricing
                    handleClose={this.handleClose}
                    open={this.state.modalOpen}
                  />
                </div>
              </Modal>
            )} */}

            <Grid container className={classes.scroll}>
              <Grid item xs={12}>
                <List dense={true}>
                  <Data props={this.props} home={home} />
                </List>
              </Grid>
            </Grid>

            <List style={{ marginTop: "auto" }}>
              <ListItem
                button
                style={{
                  padding: 12,
                  paddingLeft: "22px",
                }}
                onClick={this.handleLogOut}
              >
                <ListItemIcon
                  style={{ color: this.props.theme.palette.secondary.dark }}
                >
                  <ExitToAppRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography={true}
                  style={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: this.props.theme.palette.secondary.dark,
                  }}
                >
                  Logout
                </ListItemText>
              </ListItem>
            </List>

            <List>
              <Divider />
              {open ? (
                <Button
                  href="mailto:support@surereach.io"
                  style={{
                    fontSize: 12,
                    textTransform: "none",
                    fontWeight: "normal",
                  }}
                  fullWidth
                >
                  <MailOutline style={{ fontSize: 16 }} /> &nbsp;
                  support@surereach.io
                </Button>
              ) : (
                <Box display="flex" justifyContent="center">
                  <Button color="primary" href="mailto:support@surereach.io">
                    <MailOutline style={{ fontSize: 21, color: "#7A8BED" }} />
                  </Button>
                </Box>
              )}
            </List>
          </Drawer>
        )}
        <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
          {showNav && <Header />}
          {this.props.children}
        </div>
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // onboardRequired: state.auth.onboardRequired,
  authenticated: state.auth.authenticated,
  pop_up: state.auth.pop_up,
  openModal: state.modalRed.openModal,
  currentPlan: state.clientData.currentPlan,
  outerToggleDrawer: state.auth.outerToggleDrawer,
  userDetails: state.clientData.userDetails,
  userTeamDetails: state.clientData.userTeamDetails,
});

const mapDispatchToProps = {
  signOut: authActions.logout,
  changeModal: authActions.changeModal,
  currentPlanDetail: clientActions.getCurrentPlanDetails,
  setOuterToggleDrawer: authActions.setOuterToggleDrawer,
  getUserDetails: clientActions.getUserDetails,
  getUserTeamDetails: clientActions.getUserTeamDetails,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles, { withTheme: true })(MiniDrawer)),
);
