import React, { useState, useRef, useEffect } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
// import {MailOutlineIcon as EmailIcon} from '@material-ui/icons/MailOutline';\
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { useDispatch } from 'react-redux';
import CustomSnackbar from 'views/components/custom-snackbar';
import { CircularProgress } from '@material-ui/core';
import { sendOptToEmali , submitOptEmail , addEmail } from 'getDetails/actions';
export default function OtpStep({ email, onSubmit, onEditEmail, otpEmail, setOtpEmail , handleEmailSubmit }) {
  const classes = useStyles();
  const [otp, setOtp] = useState(['', '', '', '']);
  const [errorMesg, setErrorMesg] = useState()
  const [timeLeft, setTimeLeft] = useState(1); // 10 minutes in seconds
  const dispatch = useDispatch()
  const { loader, reachData, companyDetailData, directorDetailData, reachDirectorData, loading, emailRes, errormsg, userDetails, errormsgotp, addEmailRes, OtpRes , submitEmailLoader } = useSelector((state) => state.clientData);
  console.log("submitEmailLoader", submitEmailLoader);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, value) => {
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value !== '' && index < 3) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(otp.join(''));
  };

 

  const handleResendOtp =()=>{
    dispatch(sendOptToEmali())
  }

  const handleResClose = () => {
    dispatch({
      type: "SEND_OTP_SUCCESS", payload: null
    })

    dispatch({
      type: "SEND_EMAIL_SUCCESS", payload: null
    })
  }

  const handleEmailClose=()=>{
    dispatch({
      type: "SEND_OTP_FAILURE", payload: null
    })
    dispatch({
      type: "SEND_EMAIL_FAILURE", payload: null
  })
    setErrorMesg(null)
  }

  useEffect(() => {
    setOtpEmail(otp)
  }, [otp])

  useEffect(()=>{
    if(errormsg?. data?.message){
      setErrorMesg(errormsg?. data?.message)
    }
    if(errormsgotp?.message){
      setErrorMesg(errormsgotp?.message)
    }
  },[ errormsg , errormsgotp])

  return (
    <div className={classes.stepContainer}>
      <div className={classes.logo}>
        <MailOutlineIcon />
      </div>
      <Typography variant="h4" className={classes.title}>
        Enter Verification Code
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        We've sent a 4-digit verification code to {email}.

        
        {/* 
         // For future scope to Edit Email
        <Button onClick={onEditEmail} className={classes.resendButton}>
          Edit Email
        </Button> */}
      </Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <div className={classes.otpContainer}>
          {otp.map((digit, index) => (
            <TextField
              key={index}
              inputRef={inputRefs[index]}
              type="text"
              inputProps={{
                maxLength: 1,
              }}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              variant="outlined"
              required
              className={classes.otpInput}
            />
          ))}
        </div>
       
        <Typography variant="body2" className={classes.resendText}>
          Didn't receive the code?{' '}
          <Button
            className={classes.resendButton}
            onClick={handleResendOtp}
          >
            Resend
          </Button>
        </Typography>
        <Button
          type="submit"
          variant="contained"
          className={classes.submitButton}
          disabled={timeLeft === 0 || submitEmailLoader}
        >
        {submitEmailLoader?
        <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
        <CircularProgress style={{'color': 'white'}} size={30}/> 
        <div style={{marginLeft: "20px"}}> Loading...</div>
        </div>:
         " Verify and Proceed"
        }
        </Button>
      </form>


      <CustomSnackbar
        display={!!OtpRes?.email}
        message={"Successfully Verified !"}
        dismissNotification={handleResClose}
        severity={"success"}
      />
    

      <CustomSnackbar
        display={!!errorMesg} 
        message={errorMesg}
        dismissNotification={handleEmailClose} 
        severity={"error"}
      />

      <CustomSnackbar
        display={!!emailRes?.client_id}
        message={"OTP send successfully"}
        dismissNotification={handleResClose}
        severity={"success"}
      />
    </div>
  );
}

