
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  verificationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#f0f4f8',
  },
  dialogPaper: {
    borderRadius: '16px !important',
    maxWidth: '450px !important',
    width: '100% !important',
    margin: '20px !important',
    boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1) !important',
  },
  dialogContent: {
    padding: '40px !important',
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  logo: {
    width: '80px',
    height: '80px',
    marginBottom: '24px',
    backgroundColor: '#d4dff6',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      width: '40px',
      height: '40px',
      color: '#221c53',
    },
  },
  title: {
    color: '#221c53 !important',
    marginBottom: '16px !important',
    fontSize: '20px !important',
    fontWeight: 700,
  },
  subtitle: {
    color: '#4a5568 !important',
    marginBottom: '32px !important',
    maxWidth: '400px !important',
    lineHeight: 1.6,
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  otpContainer: {
    display: 'flex',
    gap: '12px',
    justifyContent: 'center',
    marginBottom: '24px',
  },
  resendText: {
    marginBottom: '24px !important',
    color: '#4a5568',
  },
  submitButton: {
    backgroundColor: '#221c53 !important',
    color: 'white !important',
    width: '100%',
    padding: '12px 0 !important',
    fontSize: '1rem !important',
    fontWeight: '500 !important',
    textTransform: 'none !important',
    transition: 'all 0.3s ease !important',
    '&:hover': {
      backgroundColor: 'white !important',
      color: '#221c53 !important',
      outline: '2px solid #221c53',
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1) !important',
    },
  },
  otpInput: {
    width: '50px !important',
    height: '60px !important',
    '& input': {
      textAlign: 'center',
      fontSize: '24px',
      padding: '12px 0',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#221c53',
      },
    },
  },
  emailInput: {
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#221c53',
      },
      '&.Mui-error fieldset': {
        borderColor: '#ff1744',
      },
    },
    '& .MuiInputAdornment-root svg': {
      color: '#221c53',
      width: '20px',
      height: '20px',
    },
  },
  resendButton: {
    color: '#221c53 !important',
    fontWeight: '600 !important',
    textTransform: 'none !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
      textDecoration: 'underline',
    },
  },
  errorText: {
    color: '#ff1744',
    fontSize: '0.75rem',
    marginTop: '8px',
    textAlign: 'left',
  },

 

}));

export default useStyles;

