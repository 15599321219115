import React from 'react';
import {Grid, createTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a modalLoader
import BalanceExhsustedImg from "../../../src/assets/phir-hera-pheri-paisa-laya.gif";

// opacity: 1;
// transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
// position: absolute;
// left: 50%;
// top: 50%;
// transform: translate(-50%, -50%);
// width: 55rem;
// height: 28rem;

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    flexDiv: {
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
    },
    heading: {
        fontWeight: 600,
    },
    contentDiv: {
        margin: 20,
    },
    buttonDiv: {
        display: "flex",
        margin: "4px 0 50px 0",
        justifyContent: "center",
    },
    acceptButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "#221c53",
        "&:hover": {
            background: "#fff",
            color: theme.palette.primary.main,
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    rejectButton: {
        color: "#fff",
        padding: "6px 6px 6px 0",
        width: "10rem",
        fontSize: "14px",
        boxShadow:
            " 0 4px 4px -2px rgb(0 0 0 / 10%), 0 8px 16px 0 rgb(0 0 0 / 10%)",
        fontWeight: "800",
        borderRadius: "8px",
        letterSpacing: "1px",
        textTransform: "capitalize",
        backgroundColor: "red",
        "&:hover": {
            background: "#fff",
            color: "red",
        },
        "& .MuiTouchRipple-root": {
            opacity: 0.2,
            zIndex: -1,
        },
    },
    styleFont: {
        fontSize: 22,
        lineHeight: 1.4,
        fontWeight: 600,
    },
});
const DialogTitle = withStyles(styles)((props) => {
    const {children, classes, onClose, ...other} = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.heading}>
                {children}
            </Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1.5),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
        justifyContent: "center",
    },
}))(MuiDialogActions);

const getMUITheme = createTheme({
    overrides: {
        MuiPaper: {
            root: {
                height: "100%",
            },
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: "310px !important",
            },
            paperWidthMd: {
                width: "900px",
                // height: "190px"
                maxHeight: "calc(100% - 200px)",
            },
        },
    },
});

function CreditsEnhaustedPopupComponent(props) {
    const {open, handleClose, classes} = props;
    const dispatch = useDispatch();

    // const { statusMessage, modalLoader, errormsg } = useSelector(state => state.clientData)

    return (
        <MuiThemeProvider theme={getMUITheme}>
            <Dialog
                onClose={handleClose}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                    width: "100%",
                    height: "100%",
                }}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={false}
                maxWidth={"md"}
            >
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Credits Exhausted
                </DialogTitle>

                <DialogContent dividers>
                    <Grid
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <img
                            alt={""}
                            width={600}
                            height={400}
                            src={BalanceExhsustedImg}
                        ></img>
                        <Typography
                            style={{fontSize: "20px", fontWeight: "600", padding: "20px"}}
                        >
                            You have exhausted your credits, To continue using our services
                            please recharge.
                        </Typography>
                        <Button
                            href={"https://surereach.io/#plan-pricing"}
                            target={"_blank"}
                            className={classes.acceptButton}
                            style={{padding: "12px"}}
                        >
                            Recharge Now
                        </Button>
                    </Grid>
                </DialogContent>
            </Dialog>
        </MuiThemeProvider>
    );
}

CreditsEnhaustedPopupComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CreditsEnhaustedPopupComponent);
