import React from "react";
import { InputAdornment, makeStyles, TextField } from "@material-ui/core";
import {
  forwardRef,
  Fragment,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import ClearIcon from "@material-ui/icons/Clear";
import { useEffect } from "react";

const useStyles = makeStyles({
  textField: {
    fontSize: 12,
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "5.5px 8px !important",
    },
  },
});

// eslint-disable-next-line react/display-name
export default forwardRef((props, ref) => {
  const [currentValue, setCurrentValue] = useState(null);
  const inputRef = useRef(null);
  const classes = useStyles();

  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        console.log("ParentModal", parentModel);
        if (!parentModel) {
          setCurrentValue("");
        } else {
          setCurrentValue(parentModel.filter);
        }
      },
    };
  });

  useEffect(() => {
    if (currentValue === null) {
      console.log("Value in useEffect", currentValue);
      props.parentFilterInstance((instance) => {
        console.log("Instacne", instance);
        instance.onFloatingFilterChanged(null, null);
      });
    }
  }, [currentValue]);

  const onInputBoxChanged = (input) => {
    console.log("Value", input.target.value);

    if (input.target.value === "") {
      // Remove the filter
      console.log("Parent", props.parentFilterInstance);
      props.parentFilterInstance((instance) => {
        console.log("Instacne", instance);
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }
    setCurrentValue(input.target.value);
    props.parentFilterInstance((instance) => {
      console.log("Instance", instance);
      instance.onFloatingFilterChanged("contains", input.target.value);
    });
  };

  // const onClearButtonPress = (input) => {
  //     input.target.style.opacity = 0
  //     props.parentFilterInstance((instance) => {
  //         instance.onFloatingFilterChanged("text", null);
  //     });
  // }

  return (
    <Fragment>
      <TextField
        refv={inputRef}
        variant={"outlined"}
        size={"small"}
        value={currentValue}
        className={classes.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ClearIcon
                style={{ cursor: "pointer" }}
                fontSize={"small"}
                onClick={() => {
                  setCurrentValue(null);
                }}
              />
            </InputAdornment>
          ),
        }}
        onChange={onInputBoxChanged}
      />
    </Fragment>
  );
});
