import React from 'react'

export default function NoDataFound() {
  return (
    <div className="no-data-container">
      <div className="no-data-icon">
        <svg viewBox="0 0 100 100" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          {/* Paper with folded corner */}
          <path d="M25 20 L65 20 Q66 20 67 21 L75 29 Q76 30 76 31 L76 80 Q76 82 74 82 L25 82 Q23 82 23 80 L23 22 Q23 20 25 20" />
          <path d="M65 20 L65 31 Q65 33 67 33 L76 33" />
          
          {/* Sad face */}
          <circle cx="37" cy="45" r="2" fill="currentColor" /> {/* Left eye */}
          <circle cx="63" cy="45" r="2" fill="currentColor" /> {/* Right eye */}
          <path d="M40 65 Q50 55 60 65" /> {/* Sad mouth */}
        </svg>
      </div>
      <h2 className="no-data-title">No Data Found</h2>
      <p className="no-data-message">We couldn't find any data to display.</p>
      <style jsx>{`
        .no-data-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 250px;
          background-color: #ffffff;
          border: 1px solid #e0e0e0;
          border-radius: 12px;
          padding: 24px;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
        }

        .no-data-icon {
          width: 100px;
          height: 100px;
          margin-bottom: 20px;
          color: #6c757d;
        }

        .no-data-icon svg {
          width: 100%;
          height: 100%;
        }

        .no-data-title {
          font-size: 24px;
          font-weight: 600;
          color: #343a40;
          margin-bottom: 8px;
          text-align: center;
        }

        .no-data-message {
          font-size: 16px;
          color: #6c757d;
          text-align: center;
          max-width: 250px;
          line-height: 1.5;
        }

        @media (max-width: 480px) {
          .no-data-container {
            height: 220px;
            padding: 20px;
          }

          .no-data-icon {
            width: 80px;
            height: 80px;
          }

          .no-data-title {
            font-size: 20px;
          }

          .no-data-message {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  )
}

