import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Button, Fade } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import EmailStep from './EmailStep';
import OtpStep from './OtpStep';
import useStyles from './styles';
import { sendOptToEmali, submitOptEmail, addEmail } from 'getDetails/actions';

import { getUserDetails } from 'getDetails/actions';
import { useSelector } from 'react-redux';
import EditEmail from './EditEmail';
export default function EmailVerification({ select, open, setOpen }) {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [step, setStep] = useState("email");
    const [editEmail, setEditEmail] = useState(false);
    const [otpEmail, setOtpEmail] = useState();
    let { loader, reachData, companyDetailData, addEmailRes, directorDetailData, reachDirectorData, loading, emailRes, errormsg, userDetails, errormsgotp, OtpRes } = useSelector((state) => state.clientData);
    const dispatch = useDispatch()
    const [errorMesg, setErrorMesg] = useState(errormsgotp?.message);
    console.log("emailResemailRes", emailRes);

    const handleEmailSubmit = (submittedEmail) => {
        setEmail(submittedEmail);
        if (userDetails?.email) {
            dispatch(sendOptToEmali())
        } else {
            let payload = {
                "email": submittedEmail
            }
            dispatch(addEmail(payload))
        }
    };

    const handleOtpSubmit = (params) => {
        console.log("emailResemailRes" ,  emailRes?. data?.client_id )
        let payload
        if (params === "email") {
            setStep('email');

        }

        payload = {
            "client_id": emailRes?. data?.client_id ,
            "otp": otpEmail.join("")
        }

        if (userDetails?.email) {
            dispatch(submitOptEmail(payload))
        }
    };

    const handleEdit = () => {
        setStep('email');
    }

    useEffect(() => {
        setOpen(select !== "Din");
        setStep("email")
    }, [select]);


    useEffect(() => {
        if (emailRes) {
            setStep('otp');
        }
    }, [emailRes])

    useEffect(() => {
        setOpen(false);
        setStep('email');
    }, [])


    useEffect(() => {
        setStep("email")
    }, [open])

    useEffect(() => {
        if (OtpRes) {
            setOpen(false)
            dispatch(getUserDetails())
        }

    }, [OtpRes])


    

    return (
        <div className={classes.verificationContainer}>
            <Dialog
                open={open}
                onClose={() =>{ 
                    
                    setOpen(false)
                    setEditEmail(false)
            
            
            }}
                PaperProps={{
                    className: classes.dialogPaper
                }}
                maxWidth="md"
                fullWidth
                TransitionComponent={Fade}
                transitionDuration={300}
            >
                <DialogContent className={classes.dialogContent}>
                    {step === 'email' && (
                        editEmail ? (
                            <EditEmail setEditEmail={setEditEmail}  editEmail={editEmail}  setOpen={setOpen}/>
                        ) : (
                            <EmailStep
                                onSubmit={handleEmailSubmit}
                                setEditEmail={setEditEmail}
                                editEmail={editEmail}
                            />
                        )
                    )}

                    {step === 'otp' && (
                        <OtpStep
                            onSubmit={handleOtpSubmit}
                            handleEmailSubmit={handleEmailSubmit}
                            email={email}
                            onEditEmail={handleEdit}
                            otpEmail={otpEmail}
                            setOtpEmail={setOtpEmail}
                        />
                    )}
                </DialogContent>

            </Dialog>



        </div>
    );
}