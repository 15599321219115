import React, { useEffect, useState } from 'react';
import './collapsibleTable.css';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MobileResponsiveTable from './MobileResponsiveTable';
import CopyClipIcon from 'views/components/CopyClipIcon';
import NoDataFound from 'views/components/NoDataFound';
import { Snackbar } from '@material-ui/core';
import CopyWithPopup from 'views/components/CopyWithPopup';
const CollapsibleTable = ({ headers = [], data = [], detailsData, select }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [loadingRow, setLoadingRow] = useState([]);
  const [showHideData, setShowHideData] = useState({})
  const [rowDetails, setRowDetails] = useState({});
  const [styleCheack, setstyleCheack] = useState(false);
  const { loader, reachData, companyDetailData, directorDetailData, reachDirectorData, loading } = useSelector((state) => state.clientData);
  const { emailPhoneDetails, errormsg } = useSelector((state) => state.planReducer,);
  const indices = data.map((_, index) => index);
  let tempArray = headers
  console.log("showHideData", showHideData, rowDetails);
  console.log("loadingRow", loadingRow);
  const formatColumnName = (columnName) => {
    console.log("formatColumnName", columnName);
    const words = columnName?.split(/(?=[A-Z])|_/).map(word => {
      if (word == "din") {
        word.replace("din", "Din")
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return words.join(' ');
  };

  const downloadCSV = (tableData, tableHeaders, fileName) => {
    const csvContent = [
      tableHeaders.join(','),
      ...tableData.map((row) => tableHeaders.map((header) => row[header]).join(',')),
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  function modifyArray(arr) {
    // Find the indexes of 'first_name' and 'last_name'
    const firstNameIndex = arr.indexOf('first_name');
    const lastNameIndex = arr.indexOf('last_name');

    console.log("firstNameIndex", firstNameIndex, lastNameIndex)
    // Remove 'first_name' and 'last_name' if they exist
    if (firstNameIndex !== -1) {
      arr.splice(firstNameIndex, 1);
    }
    if (lastNameIndex !== -1) {
      // After removing 'first_name', the index of 'last_name' may change, so we adjust accordingly
      const adjustedLastNameIndex = lastNameIndex < firstNameIndex ? lastNameIndex : lastNameIndex - 1;
      arr.splice(adjustedLastNameIndex, 1);
    }

    // Insert 'full_name' at index 1
    arr.splice(1, 0, 'full_name');

    return arr;
  }





  const toggleRow = async (rowIndex, row) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setLoadingRow(prevLoadingRow => [...prevLoadingRow, rowIndex]);
      try {

        let condition = indices.every((item) => Object.keys(rowDetails).includes(String(item)))

        if (!showHideData[rowIndex] && !condition) {
          const details = await detailsData(data[rowIndex]);
          setstyleCheack(true);
          console.log("detailsdetails", details);
          setRowDetails((prev) => ({ ...prev, [rowIndex]: details }));
          setShowHideData(prev => ({ ...prev, [rowIndex]: true }));
          setExpandedRow(rowIndex);
        }
        else {
          setShowHideData(prev => ({ ...prev, [rowIndex]: !showHideData[rowIndex] }));
        }

      } catch (error) {
        console.error("Error fetching details:", error);
      } finally {
        setLoadingRow(prevLoadingRow =>
          prevLoadingRow.filter(index => index !== rowIndex)
        );
      }
    }
  };


  useEffect(() => {
    setRowDetails({});
    setLoadingRow([]);
    setShowHideData({});

  }, [data])



  console.log("rowDetailsrowDetails", rowDetails, data, loadingRow);


  const CopyableCell = ({ value, isCopyable }) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <span className='main-table-data' style={{ marginRight: "10px" }}>{value}</span>
        {(isCopyable && value) && (
          <CopyWithPopup text={value} type="mobile" />
        )}
      </div>
    );
  };


  const renderDetailTable = (tableData = [], title, fileName) => {
    console.log("tableData", tableData);
    return (
      <div className="detail-table-wrapper">
        <div className="detail-table-header">
          <h3 className="details-title">{title}</h3>
          <button
            className="download-button"
            onClick={() => downloadCSV(tableData.rows, tableData.headers, fileName)}
          >
            Download CSV
          </button>
        </div>
        <div className="table-scroll-container">
          {tableData?.rows?.length > 0 ? <table className="details-table">
            <thead>
              <tr>
                {tableData?.headers?.map((header, index) => {

                  return (<th key={index}> <span className='main-table-heading'> {formatColumnName(header)}</span></th>)
                }
                )}
              </tr>
            </thead>
            <tbody>

              {tableData?.rows?.map((detailRow, detailIndex) => (
                <tr key={detailIndex}>
                  {tableData?.headers?.map((header, cellIndex) => {
                    const isCopyable = ['email', 'phone_no'].includes(header) &&
                      detailRow["email"] !== null &&
                      detailRow["phone_no"] !== undefined;

                    return (
                      <td key={cellIndex} >
                        <CopyableCell
                          value={detailRow[header]}
                          isCopyable={isCopyable}
                        />
                      </td>)


                  })}
                </tr>
              ))}
            </tbody>
          </table>

            :
            <NoDataFound />
          }
        </div>
      </div>
    )

  };


  return (
    <div className="table-container">
      <div className="main-table-header">
        <h2 className="main-table-title">Director Data</h2>
        <button
          className="download-button"
          onClick={() => downloadCSV(data, headers, 'employee_directory.csv')}
        >
          Download CSV
        </button>
      </div>
      <div className="table-scroll-container">
        {data.length > 0 ? <table className="collapsible-table">
          <thead>
            <tr>
              
              {headers.map((header, index) => {
                if (header === "last_name") {
                  return null; // No rendering for last_name column
                }

                return (
                  <th key={index}>
                    <span className='main-table-heading'>{formatColumnName(header)}</span>
                  </th>
                );
              })}

              <th className="actions-column">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => {
              console.log("hhhhhhh", loadingRow[rowIndex] == rowIndex, rowIndex, loadingRow[rowIndex])

              return (<React.Fragment key={rowIndex}>
                <tr className={expandedRow === rowIndex ? 'expanded' : ''}>
                  {headers?.map((header, cellIndex) => {
                    let cellValue;

                    if (header === "first_name" || header === "last_name") {
                      // Merge first and last name to create full name
                      const firstName = row["first_name"] ?? "Not Found";
                      const lastName = row["last_name"] ?? "Not Found";
                      cellValue = `${firstName} ${lastName}`.trim();
                    } else {
                      // Default behavior for other headers
                      cellValue = row[header] ?? "Not Found";
                    }

                    // Skip rendering the td for "last_name" header
                    if (header === "last_name") {
                      return null; // No rendering for last_name column
                    }

                    console.log("cellValue", cellValue, row);
                    return (
                      <td key={cellIndex}>
                        <span className='main-table-data'>
                          {cellValue}
                        </span>
                      </td>
                    );
                  })}

                  <td className="actions-column">
                    <button
                      className="details-button"
                      onClick={() => toggleRow(rowIndex, row)}
                      aria-expanded={expandedRow === rowIndex}
                      aria-controls={`details-${rowIndex}`}
                      disabled={Object.keys(rowDetails ?? {}).some(index => rowIndex == index) || (loadingRow?.some((index) => (rowIndex == index)))}
                    // disabled={loadingRow[rowIndex]==rowIndex }
                    >

                      {
                        (loadingRow?.some((index) => (rowIndex == index))) ? (
                          <span style={{ width: "100px" }}>Loading...</span>
                        ) :
                          (<span style={{ width: "100px" }}> Get  Details</span>)
                      }


                      {/* 
                      {loadingRow?.some((index) => rowIndex == index) ? (
                        <span style={{ width: "100px" }}>Loading...</span>
                      ) : Object.keys(rowDetails).some(key => Number(key) < data.length) ? (
                        <span style={{ width: "100px" }}>Get Details</span>
                      ) : (
                        <span style={{ width: "100px" }}>Get Details</span>
                      )} */}

                    </button>
                  </td>
                </tr>

                {

                  // Object.keys(rowDetails).some(index => (rowIndex == index)) &&


                  showHideData[rowIndex] &&

                  (
                    <tr className="details-row">
                      <td colSpan={headers.length + 1}>
                        <div id={`details-${rowIndex}`} className="details-content">
                          <div className="table-scroll-container">
                            {renderDetailTable(
                              rowDetails[rowIndex]?.table1,
                              "DIN Details",
                              `Din_${rowIndex}.csv`
                            )}
                          </div>
                          {/* {showTwoTables(row) && rowDetails[rowIndex].table2 && (
                          <div className="table-scroll-container">
                            {renderDetailTable(
                              rowDetails[rowIndex].table2, 
                              "Table 2", 
                              `employee_${row.ID}_table2.csv`
                            )}
                          </div>
                        )} */}
                          {
                            select == "Director" &&
                            <div style={{ marginTop: "50px" }}>
                              {console.log("MobileResponsiveTable", rowDetails[rowIndex]?.companyDetails, rowIndex, rowDetails[rowIndex])}
                              <MobileResponsiveTable
                                data={rowDetails[rowIndex]?.companyDetails}
                                select={select}
                                styleCheack={styleCheack}
                              />
                            </div>

                          }
                        </div>
                      </td>
                    </tr>
                  )}
              </React.Fragment>)
            }
            )}
          </tbody>
        </table>
          :
          <NoDataFound />

        }
      </div>



    </div>
  );
};


export default CollapsibleTable;