import axios from 'axios'
import {axiosinstance} from '../utils/axios-instance'

console.log('Process', process.env);

export function getClientsListCall() {
    return axiosinstance.get(`api/v1/esign/realm/list`)
}

export function getClientDetailsCall(client_id) {
    return axiosinstance.get(`api/v1/esign/realm/details/${client_id}`)
}

export function getPlanListDetails() {
    return axiosinstance.get(`/api/v1/users/chargebee-plans?product=scrapingpass`)
}

export function checkOutPlan(id) {
    // console.log("Id in manager Checkout", id);
    return axiosinstance.post(`/api/v1/users/chargebee-checkout?plan_id=${id}`)
}

export function getAuthLogs() {
    return axiosinstance.get(`/api/v1/users/login-audit-log`)
}

export function getBillingDetail() {
    return axiosinstance.get(`/api/v1/users/chargebee-recharge-history`)
}

export function getClientInfo() {
    return axiosinstance.get(`/api/v1/users/profile-details`)
}

export function getCurrentPlan() {
    return axiosinstance.get('/api/v1/users/chargebee-subscription-details')
}

export function getApiUsage() {
    return axiosinstance.get('/api/v1/surereach/users/get-api-usage')
}

export function getLeadsExtractedCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-leads-extracted')
}

export function getGstLeadsExtractedCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-gst-logs')
}

export function getRechargeHistoryCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-recharge-history')
}

export function getUserDetailsCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-user-details')
}

export function getUserTeamDetailsCall(data) {
    return axiosinstance.get('/api/v1/surereach/users/get-user-team-details', {params: data})
}

export function getLinkedinRequestsCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-linkedin-requests')
}

export function getLinkedinBulkRequestCall() {
    return axiosinstance.get('/api/v1/surereach/users/get-linkedin-bulk-requests')
}

export function getResolvedLinkedinBulkRequestCall(data) {
    return axiosinstance.get(`/api/v1/surereach/users/get-resolved-linkedin-bulk-request`, {params: data})
}

export function submitLinkedinBulkRequestCall(data) {
    return axiosinstance.post(`/api/v1/surereach/users/submit-linkedin-bulk-request`, data)
}

export function getMobileEmailFromDinCall(data) {
    return axiosinstance.post(`/api/v1/surereach/users/mobile-email-from-din`, data)
}

export function getMobileEmailFromGSTCall(data) {
    return axiosinstance.post(`/api/v1/surereach/users/mobile-email-from-gst`, data)
}

export function getTeamCall(team_id) {
    return axiosinstance.get(`/api/v1/surereach/teams/get-team?team_id=${team_id}`)
}

export function getAllTeamsCall() {
    return axiosinstance.get(`/api/v1/surereach/teams/get-all-teams`)
}

export function createTeamCall(data) {
    return axiosinstance.post(`/api/v1/surereach/teams/create-team`, data)
}

export function deleteTeamCall(team_id) {
    return axiosinstance.delete(`/api/v1/surereach/teams/delete-team?team_id=${team_id}`)
}

export function addTeamMemberCall(data) {
    return axiosinstance.post('/api/v1/surereach/teams/add-team-member', data)
}

export function removeTeamMemberCall(data) {
    return axiosinstance.delete('/api/v1/surereach/teams/remove-team-member', {data: data})
}

export function getTeamMemberDetailsCall(data) {
    return axiosinstance.get('/api/v1/surereach/teams/get-team-member-details', {params: data})
}

export function incrementAllottedCreditsCall(data) {
    return axiosinstance.post('/api/v1/surereach/teams/increment-allotted-credits', data)
}

export function reportLeadExtractedCall(data) {
    return axiosinstance.post(`/api/v1/surereach/users/report-lead-extracted`, data)
}

export function reportLinkedinProfileCall(data) {
    return axiosinstance.post(`/api/v1/surereach/users/report-linkedin-profile-request`, data)
}

export function cancelSubscription() {
    return axiosinstance.post('/api/v1/users/chargebee-cancel')
}

export function changePlanAPI(value) {
    return axiosinstance.post('/api/v1/users/chargebee-change-plan', value)
}

export function cancelScheduleChangePlanAPI() {
    return axiosinstance.post('/api/v1/users/chargebee-remove-scheduled-plan-change')
}

export function removeCancelScheduleAPI() {
    return axiosinstance.post('/api/v1/users/chargbee-remove-cancellation')
}


export function estimatePriceApi(value) {
    return axiosinstance.post('/api/v1/scrapingpass/estimate-price', value)
}

export function searchCompanyServerCall(query, token) {
    return axiosinstance.get('/reach/masterdata/api/v1/mca/search-company', {
        params: { query }, 
    });
}



export function sendOtpEmailCall(value){
    return axiosinstance.post('/api/v1/surereach/users/send-email-otp', value)
}

export function submitOtpCall(value){
    return axiosinstance.post('/api/v1/surereach/users/submit-email-otp', value)
}

export function addEmailCall(value){
    return axiosinstance.post('/api/v1/surereach/users/add-email', value)
}

export function searchDirectorServerCall(name, token) {
    return axiosinstance.get('/reach/masterdata/api/v1/mca/search-director', {
        params: { query:name }, 
    });
}

const encodeToken = (token) => {
    return btoa(token.split('').reverse().join(''));
  };
  
  // Token decoding
  const decodeToken = (encodedToken) => {
    return atob(encodedToken).split('').reverse().join('');
  };
  
  // Modified version of your existing function
 


export function getDirectorDetailsCall(identification_number) {
    return axiosinstance.get(`/reach/masterdata/api/v1/mca/company-director-details?identification_number=${identification_number}`);
   
}

export function getCompanyDetailsCall(identification_number) {
    return axiosinstance.get(`/reach/masterdata/api/v1/mca/cin-details?identification_number=${identification_number}`);
   
}

export const getCreditBalanceAPI = () => axiosinstance.get('/api/v1/surereach/users/get-credits-balance')

export const getEmailVerifyAPI = (payload)=>axiosinstance.post('/api/v1/surereach/users/verify-email', payload);
