/* eslint-disable no-case-declarations */
import {
  CANCEL_CHANGE_PLAN,
  CANCEL_CHANGE_PLAN_FAILURE,
  CANCEL_SUBSCRIPTION,
  CHANGE_PLAN,
  CHANGE_PLAN_FAILURE,
  CHECKOUT_DETAIL_FAILURE,
  ESTIMATE_REQ_COST_PRICE,
  ESTIMATE_REQ_COST_PRICE_FAILURE,
  FIRST_POPUP,
  REMOVE_CANCEL_SCHEDULE,
  REMOVE_CANCEL_SCHEDULE_FAILURE,
  SET_RESPONSE,
} from "getDetails";


import {
    BILLING_DETAIL,
    BILLING_DETAIL_FAILURE,
    CHECKOUT_DETAIL,
    CLIENTS_LIST,
    CLIENT_DETAILS,
    INITIAL_CHECKOUT,
    PLANDETAIL,
    PLAN_ID,
    PROFILE_DETAIL,
    AUTH_LOG,
    AUTH_LOG_FAILURE,
    API_USAGE,
    API_USAGE_FAILURE,
    CURRENT_PLAN_FAILURE,
    PLANDETAIL_FAILURE,
    PROFILE_DETAIL_FAILURE,
    CURRENT_PLAN,
    LEADS_EXTRACTED,
    GET_TOTAL_CREDITS,
    GET_EMAIL_PHONE,
    GET_EMAIL_PHONE_GST,
    GET_EMAIL_PHONE_FAILURE,
    GET_EMAIL_PHONE_FAILURE_GST,
    LEADS_EXTRACTED_FAILURE,
    GST_LEADS_EXTRACTED,
    GST_LEADS_EXTRACTED_FAILURE,
    CLEAR_ERROR,
    LINKEDIN_REQUESTS,
    LINKEDIN_REQUESTS_FAILURE,
    CREATE_TEAM,
    CREATE_TEAM_FAILURE,
    DELETE_TEAM,
    DELETE_TEAM_FAILURE,
    ADD_TEAM_MEMBER,
    ADD_TEAM_MEMBER_FAILURE,
    REMOVE_TEAM_MEMBER,
    REMOVE_TEAM_MEMBER_FAILURE,
    GET_ALL_TEAMS,
    GET_ALL_TEAMS_FAILURE,
    GET_ALL_REACHCOMPANY,
    GET_ALL_REACHCOMPANY_FAILURE,
    GET_ALL_REACHDIRECTOR,
    GET_ALL_REACHDIRECTOR_FAILURE,
    GET_COMPANY_DETAILS,
    GET_COMPANY_DETAILS_FAILURE,
    GET_DIRECTOR_DETAILS,
    GET_DIRECTOR_DETAILS_FAILURE,
    SET_MODAL_LOADER,
    DETAILS_LOADER,
    CURRENT_TEAM,
    COMPANY_DETAILS_LOADER,
    SUBMIT_LOADER,
    SEND_OTP_LOADER,
    GET_TEAM,
    GET_TEAM_FAILURE,
    SET_STATUS_MESSAGE,
    GET_TEAM_MEMBER_DETAILS,
    GET_TEAM_MEMBER_DETAILS_FAILURE,
    INCREMENT_ALLOTTED_CREDITS_FAILURE,
    INCREMENT_ALLOTTED_CREDITS,
    REPORT_LEAD_EXTRACTED,
    REPORT_LEAD_EXTRACTED_FAILURE,
    REPORT_LINKEDIN_PROFILE_FAILURE,
    GET_USER_DETAILS,
    GET_USER_DETAILS_FAILURE,
    GET_USER_TEAMS_DETAILS,
    GET_USER_TEAMS_DETAILS_FAILURE,
    GET_RECHARGE_HISTORY,
    GET_RECHARGE_HISTORY_FAILURE,
    GET_LINKEDIN_BULK_REQUESTS_FAILURE,
    GET_LINKEDIN_BULK_REQUESTS,
    SUBMIT_LINKEDIN_BULK_REQUEST,
    SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE,
    GET_RESOLVED_LINKEDIN_BULK_REQUEST,
    GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE,
    RESET_STATE,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
    SEND_OTP_SUCCESS,
    SEND_OTP_FAILURE,
    ADD_EMAIL_SUCCESS,
    ADD_EMAIL_FAILURE,
   
} from './action-types'


import {CLEAR_CHECKOUT_DATA, CLEAR_ERROR_MESSAGE, SIGN_OUT_SUCCESS} from "../auth";


export function planState() {
  return {
    cancelSubscription: null,
    cancelRemoveSchedule: null,
    changePlanData: null,
    cancelChangePlan: null,
    errormesg: "",
    errormsgst: '',
    status_code: "",
  };
}

export function clientState() {
    return {
        clientsList: null,
        clientDetails: null,
        profileData: null,
        userName: '',
        planDetail: [],
        checkout: null,
        initialCheckOut: false,
        planId: null,
        billingDetail: null,
        authLogs: null, // billingDetailFailure: null,
        authLogFailure: null,
        apiUsage: null,
        leadsExtracted: null,
        gstLeadsExtracted:null,
        currentPlan: null,
        errormsg: '',
        statusCode: '',
        requestCostPrice: null,
        responseData: null,
        emailPhoneDetails: null,
        emailPhoneDetailsGST: null,
        linkedinRequests: null,
        linkedinBulkRequests: null,
        linkedinBulkRequestDownloadUrl: "",
        referral_code: '',
        teamData: null,
        reachData:null,
        reachDirectorData:null,
        companyDetailData:null,
        directorDetailData:null,
        currentTeamData: null,
        currentMemberDetails: null,
        currentMemberLeadsExtracted: null,
        currentMemberLinkedinRequests: null,
        statusMessage: '',
        modalLoader: false,
        loader: false,
        loading:false,
        sendEmailLoader:false,
        submitEmailLoader:false,
        userDetails: null,
        userTeamDetails: null,
        rechargeHistory: null,
        emailRes:null,
        OtpRes:null,
        errormsgotp:null,
        errormsgemail:null,
        addEmailRes:null,
        permissionList:[]
    }
}

export function planReducer(state = new planState(), {payload, type}) {

  console.log("planReducer", payload, type)
    switch (type) {
        case GET_EMAIL_PHONE:
            return {
                ...state, emailPhoneDetails: payload || null, errormsg: ''
            }
        case GET_EMAIL_PHONE_FAILURE:
            return {
                ...state, errormsg: payload ? payload : ''
            }

        case GET_EMAIL_PHONE_GST:
            return {
                ...state, emailPhoneDetailsGST: payload || null, errormsgst: ''
            }
        case GET_EMAIL_PHONE_FAILURE_GST:
            return {
                ...state, errormsgst: payload ? payload : ''
        }

        case CLEAR_ERROR:
            return {
                ...state, errormsg: ''
            }


    case CANCEL_SUBSCRIPTION:
      return {
        ...state,
        cancelSubscription: payload,
      };

    case CHANGE_PLAN:
      // console.log("Payload",payload)
      return {
        ...state,
        changePlanData: payload,
      };
    case CANCEL_CHANGE_PLAN:
      return {
        ...state,
        cancelChangePlan: payload,
      };
    
    case REMOVE_CANCEL_SCHEDULE:
      return {
        ...state,
        cancelRemoveSchedule: payload,
      };

    case REMOVE_CANCEL_SCHEDULE_FAILURE:
      return {
        ...state,
        status_code: payload ? payload?.data?.status_code : false,
        errormesg: payload ? payload?.data?.message : "",
      };
    
    
    case CHANGE_PLAN_FAILURE:
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : "",
      };



    case CANCEL_CHANGE_PLAN_FAILURE:
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : "",
      };
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errormesg: "",
        status_code: "",
      };

    case ESTIMATE_REQ_COST_PRICE_FAILURE: {
      return {
        ...state,
        status_code: payload ? payload.data.status_code : false,
        errormesg: payload ? payload.data.message : "",
      };
    }
    case SIGN_OUT_SUCCESS:
      return new planState();

    default:
      return state;
  }
}

export function planPopState() {
  return {
    first: false,
  };
}

export function planPopReducer(state = new planPopState(), { payload, type }) {
  switch (type) {
    case FIRST_POPUP:
      return {
        ...state,
        first: payload,
      };
    case SIGN_OUT_SUCCESS:
      return new planPopState();
    default:
      return state;
  }
}

export function clientsReducer(state = new clientState(), { payload, type }) {
  switch (type) {
    case DETAILS_LOADER:
      return {
        ...state,
        loader: payload,
      };

    case COMPANY_DETAILS_LOADER:
      return {
        ...state,
        loading: payload,
      };

   

    case SEND_OTP_LOADER:
      return {
        ...state,
        sendEmailLoader: payload,
    };
    case SUBMIT_LOADER:
      return {
        ...state,
        submitEmailLoader: payload,
    };
      case RESET_STATE:
        return {
            ...state,
            directorDetails: null,
            reachDirectorData:null,
            directorDetailData:null,
            reachData :[],
            allReachCompany: [],
            allReachDirector: null,
        };

 
        
        // case GET_USER_DETAILS:
        // return {
        //   ...state,
        //   userDetails: 
          
        //   {
        //     "access_to": [],
        //     "allow_linkedin_bulk_upload": false,
        //     "client_id": "user_yaiVLrUXANQgFYeqhnfE",
        //     "created_at": "2024-04-19T11:43:59.783000",
        //     "credits_exhausted_at": "2024-12-19T10:50:45.617000",
        //     "disabled_reason": "",
        //     "email": "himanshu.rai@surepass.io",
        //     "email_credits": 74,
        //     "email_credits_exhausted_at": null,
        //     "email_verified": false,
        //     "enabled": true,
        //     "fetch_live": false,
        //     "is_premium": false,
        //     "is_verified": true,
        //     "last_critical_update": 1713527039.7834055,
        //     "last_updated": "2024-12-20T05:23:26.598000",
        //     "linkedin_url": null,
        //     "linkedin_verified": false,
        //     "name": "Himanshu",
        //     "onboarded": true,
        //     "permissions": [
        //         "surereach-sub-admin",
        //         "surereach-access"
        //     ],
        //     "referral_code": "RsAR5Cu45gskjkt5PsT3RkQke",
        //     "referred_by": null,
        //     "tags": [],
        //     "team_id": null,
        //     "total_credits": 0,
        //     "user_mobile": "+918736994487"
        // },
        // };

        case GET_USER_DETAILS:
        return {
          ...state,
          userDetails: payload,
        };

    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        errormsg: payload,
      };
    case GET_USER_TEAMS_DETAILS:
      return {
        ...state,
        userTeamDetails: payload,
      };
    case GET_RECHARGE_HISTORY:
      return {
        ...state,
        rechargeHistory: payload || [],
      };
    case GET_RECHARGE_HISTORY_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case GET_USER_TEAMS_DETAILS_FAILURE:
      return {
        ...state, // errormsg: payload || ""
      };

    case SET_MODAL_LOADER:
      return {
        ...state,
        modalLoader: payload,
      };
    case SET_STATUS_MESSAGE:
      return {
        ...state,
        statusMessage: payload,
      };
    case GET_TEAM:
      return {
        ...state,
        currentTeamData: payload,
      };
    case GET_TEAM_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };

    case GET_ALL_TEAMS:
      return {
        ...state,
        teamData: payload || [],
      };
    case GET_ALL_TEAMS_FAILURE:
      return {
        ...state,
        teamData: [],
        errormsg: payload || "",
      };

      case GET_ALL_REACHCOMPANY:
            return {
                ...state,
                reachData: payload?.data || [],
                errormsg: "" }
      case GET_ALL_REACHCOMPANY_FAILURE:
            return {
                ...state,
                reachData: [],
                errormsg: payload || "An error occurred"
            };

      case GET_ALL_REACHDIRECTOR:
            return {
                ...state,
                reachDirectorData: payload?.data ,
                errormsg: "" }
      case GET_ALL_REACHDIRECTOR_FAILURE:
            return {
                ...state,
                reachDirectorData: [],
                errormsg: payload || "An error occurred"
            };

      case GET_COMPANY_DETAILS:
            return {
                ...state,
                companyDetailData: payload?.data || [],
                errormsg: "" }
      case GET_COMPANY_DETAILS_FAILURE:
            return {
                ...state,
                companyDetailData: [],
                errormsg: payload || "An error occurred"
            };

            case GET_DIRECTOR_DETAILS:
              console.log("GET_DIRECTOR_DETAILS payload:", payload); // Debugging line
              return {
                ...state,
                directorDetailData: payload?.data ,
                errormsg: ""
              }
            case GET_DIRECTOR_DETAILS_FAILURE:
              return {
                ...state,
                directorDetailData: [],
                errormsg: payload || "An error occurred"
              };
    case CREATE_TEAM:
      return {
        ...state,
        teamData: [...state.teamData, payload],
      };
    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };

    case DELETE_TEAM:
      return {
        ...state,
        teamData: payload || [],
      };
    case DELETE_TEAM_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        currentTeamData: payload,
      };
    case ADD_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case REMOVE_TEAM_MEMBER:
      return {
        ...state,
        currentTeamData: payload,
      };
    case REMOVE_TEAM_MEMBER_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case GET_TEAM_MEMBER_DETAILS:
      return {
        ...state,
        currentTeamData: payload?.team_details,
        currentMemberDetails: payload?.member_details,
        currentMemberLeadsExtracted: payload?.leads_extracted,
        currentMemberLinkedinRequests: payload?.linkedin_requests,
      };
    case GET_TEAM_MEMBER_DETAILS_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case INCREMENT_ALLOTTED_CREDITS:
      return {
        ...state,
        currentMemberDetails: payload.member_details,
      };
    case INCREMENT_ALLOTTED_CREDITS_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case REPORT_LEAD_EXTRACTED_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case REPORT_LINKEDIN_PROFILE_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case GET_EMAIL_PHONE_FAILURE:
      return {
        ...state,
        errormsg: payload,
      };
    case SIGN_OUT_SUCCESS:
      // console.log("Called");
      return new clientState();
    case CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errormsg: payload,
        statusCode: payload,
      };
    case CLIENTS_LIST:
      return {
        ...state,
        clientsList: payload.data,
      };

    case SET_RESPONSE:
      return {
        ...state,
        responseData: payload,
      };
    case CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: payload.data,
      };
    case PLANDETAIL:
      return {
        ...state,

        planDetail: payload,
      };

    case ESTIMATE_REQ_COST_PRICE:
      console.log("payload", payload);
      return {
        ...state,
        requestCostPrice: payload.data?.balance_required,
      };
    case CHECKOUT_DETAIL:
      // console.log("Payload", payload);
      return {
        ...state,
        checkout: payload,
        // initialCheckOut: !state.initialCheckOut,
      };
    case CLEAR_CHECKOUT_DATA:
      return {
        ...state,
        checkout: null,
      };

    case CHECKOUT_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };
    case API_USAGE_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };
    case PROFILE_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };
    case INITIAL_CHECKOUT:
      // console.log("Initial reducerr", payload);
      return {
        ...state,
        initialCheckOut: payload,
      };
    case PLAN_ID:
      // console.log("Plan-Id in reducer", payload);

      return {
        ...state,
        planId: payload,
      };
    case BILLING_DETAIL:
      return {
        ...state,
        billingDetail: payload,
      };
    case BILLING_DETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };
    case PLANDETAIL_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };

    case CURRENT_PLAN_FAILURE:
      return {
        ...state,
        statusCode: payload ? payload.data.status_code : false,
        errormsg: payload ? payload.data.message : "",
      };
    case AUTH_LOG:
      return {
        ...state,
        authLogs: payload,
      };
    case AUTH_LOG_FAILURE:
      return {
        ...state,
        authLogFailure: payload,
      };
    // case BILLING_DETAIL_FAILURE:
    //   return {
    //     ...state,
    //     billingDetailFailure: payload,
    //   };
    case PROFILE_DETAIL:
      const name = payload?.data?.name?.split(" ");
      let firstName;
      let middleName;
      let lastName;
      if (name?.length < 1) {
        firstName = name?.[0]?.slice(0, 1);
        // console.log("FirstName", firstName);
      } else if (name?.length < 2) {
        firstName = name?.[0]?.slice(0, 1);
        lastName = name?.[1]?.slice(0, 1);
        // console.log("Username", firstName, lastName);
      } else {
        firstName = name?.[0]?.slice(0, 1);

        let lastIndex = name?.slice(-1);
        lastName = lastIndex?.[0]?.slice(0, 1);
        // console.log("Username", firstName, lastName);
      }

      return {
        ...state,
        profileData: payload,
        userName: lastName ? `${firstName}${lastName}` : firstName,
      };
    case API_USAGE:
      // console.log("API_USAGE", payload);
      return {
        ...state,
        apiUsage: payload,
      };
    case LINKEDIN_REQUESTS:
      return {
        ...state,
        linkedinRequests: payload || [],
      };
    case LINKEDIN_REQUESTS_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case GET_LINKEDIN_BULK_REQUESTS:
      console.log(payload);
      return {
        ...state,
        linkedinBulkRequests: payload || [],
      };
    case GET_LINKEDIN_BULK_REQUESTS_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
        linkedinBulkRequests: [],
      };
    case GET_RESOLVED_LINKEDIN_BULK_REQUEST:
      return {
        ...state,
        linkedinBulkRequestDownloadUrl: payload?.download_url,
      };
    case GET_RESOLVED_LINKEDIN_BULK_REQUEST_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case SUBMIT_LINKEDIN_BULK_REQUEST_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
      };
    case LEADS_EXTRACTED:
      return {
        ...state,
        leadsExtracted: payload || [],
      };

    case LEADS_EXTRACTED_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
    };

    case GST_LEADS_EXTRACTED:
      return {
        ...state,
        gstLeadsExtracted: payload || [],
      };

    case GST_LEADS_EXTRACTED_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
    };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailRes: payload || "",
    };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        errormsg: payload || "",
    };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        OtpRes: payload || "",
    };
    case SEND_OTP_FAILURE:
      return {
        ...state,
        errormsgotp: payload || "",
    };

    case ADD_EMAIL_SUCCESS:
      return {
        ...state,
        addEmailRes: payload || "",
    };
    case ADD_EMAIL_FAILURE:
      return {
        ...state,
        errormsgemail: payload || "",
    };


    case CURRENT_PLAN:
      // console.log("CurrentPlan", payload);
      return {
        ...state,
        currentPlan: payload,
      };
    default:
      return state;
  }
}
